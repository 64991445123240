.email_notice_main {
  width: 100%;
  height: calc(100vh - 6rem);
  display: flex;
  flex-direction: column;

  

  .page_main {
    flex: 1;

    .page_inner {
      height: 100%;
    }
  }

  .list_dit {
    width: 0.6rem;
    height: 0.6rem;
    border-radius: 50%;
    margin: 0 1.6rem;

    &.unread {
      background-color: #E34D59;
    }
  }

  .list_content {
    .list_content_time {
      font-size: 1.4rem;
      color: rgba(0, 0, 0, 0.45);
      margin-right: 10.8rem;
    }

    .list_content_detial {
      color: #0052D9;
      cursor: pointer;
      margin-right: 4rem;
    }
  }
}
.circle {
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin-right: 1rem;
  &.red{
    background: #E34D59;
  }
}
@primary-color: #0052D9;