.point_list_contianer {
  .point_search {
    h3 {
      font-size: 2rem;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.85);
      margin-bottom: 1.6rem;
      margin-top: 1.6rem;
    }
  }

  .table_hd {
    margin-bottom: 2rem;

    h5 {
      font-size: 1.6rem;
      font-weight: 600;
    }

    .setting {
      margin-right: 2rem;
      cursor: pointer;
      color: rgba(0, 0, 0, 0.85);
      font-size: 1.4rem;
      &:hover{
        color: #0052D9;
      }
    }
  }
}

//通知设置
.notice_edit_main {
  padding-bottom: 2rem;

  .reduce_img {
    width: 2.4rem;
    height: 2.4rem;
    display: block;
    margin-left: 1.6rem;
    cursor: pointer;
  }
}

//广播设置
.broadcast_setting_main {
  min-height: 100%;

  .ad_template {
    border-bottom: 1px solid #E9E9E9;
    margin-bottom: 2.3rem;
  }
}

//费用设置
.cost_setting_mian {
  .table_item {
    margin-bottom: 2.4rem;

    .all {
      color: #0052D9;
      cursor: pointer;
    }
  }
}

//预约
.appointment_main {
  .release_time {
    color: #E34D59;
    margin-right: 7px;
  }
}


.confirm_delayed {
  margin-left: -2.2rem !important;
}

//订单
.order_info_main {
  position: relative;
  height: calc(100vh - 6rem);
  overflow: hidden;
  padding-bottom: 6rem;

  .page_main {
    overflow-y: scroll;
  }

  .table_item {
    margin-bottom: 2.4rem;

    .table_list_title {
      .total {
        color: #E34D59;
        font-size: 1.6rem;
        margin-left: 0.8rem;
      }

      .anticon {
        vertical-align: -0.25rem !important;
      }
    }
  }

  .total_order {
    box-shadow: 0.5px -2px 2px -1px #0052D9;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 1.3rem 2.4rem;
    background: #FFFFFF;

    .total_order_hd {
      padding-bottom: 1rem;
      border-bottom: 1px solid #E8E8E8;

    }

    .total_order_info {
      color: rgba(0, 0, 0, 0.65);
      font-size: 1.4rem;

      &.line {
        margin: 0 1.8rem;
      }
    }

    .total_order_title {
      font-size: 1.6rem;
      color: #000;
      font-weight: 600;
      // margin-bottom: 1rem;   
    }

    .total_order_all {
      font-size: 1.6rem;
      color: #E34D59;
      font-weight: 600;
      margin-right: 2.4rem;
    }

    .total_order_play {
      padding: 1rem 6rem 0rem;
      justify-content: flex-end;

      .check_box {
        margin-left: 7.5rem;
      }
    }
  }
}

// 订单中的结账弹框
.check_out_inner {
  width: 440px;
  height: 800px;
  background: url('../../../assets/imgs/check_out_bg.png') no-repeat center;
  background-size: 100% 100%;
  padding: 3rem;

  ul {
    &.first {
      padding-top: 1rem;
    }

    li {
      width: 100%;
      display: flex;
      align-items: center;
      margin-bottom: 0.5rem;

      p {
        flex: 1;
        font-size: 1.4rem;
        .crad_name{
          margin-right: 1.4rem;
        }
        &:first-child {
          text-align: right;
          justify-content: flex-end;
          margin-right: 3.2rem;
        }
      }  
    }

    .change_amount{
      width: 100%;
      border: 1px solid #ddd;
      padding: 20px 30px;
    }
  }
  .modify_amount_box{
    padding-left: 60px;
  }
}

.check_out_main{
  .check_out_inner{
    .ant-form-item{
      margin-bottom: 0;
    }
  }
}

//创建订单
.create_order_container{
  display: flex;
  justify-content: center;
  .create_order_main{
    width: 52rem;
    margin-top: 4rem;
    .create_btn{
      margin-top: 2.4rem;
      margin-left: 11rem;
    }
  }

}

.lock_screen_container{
  .lock_screen_form{
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
  }
  .lock_screen_btn{  
    margin-bottom: 50px;
  }
}

@primary-color: #0052D9;