.open_close {
  color: #0052D9;
  cursor: pointer;

  .arrow {
    margin-left: 0.4rem;
    font-size: 10px;
    transition: all 0.3s;

    &.active {
      transform: rotate(180deg);
    }
  }
}

.search_bottom {
  width: 90%;
  transition: all 0.3s;
  &.hide {
    height: 0;
    overflow: hidden;
  }
  &.show{
    margin-top: 2.4rem;
    height: auto;
  }
}
@primary-color: #0052D9;