//登录页面的样式
.logo_box {
  width: 22.8rem;
  height: 6rem;
  padding-left: 1.6rem;
  padding-right: 2.2rem;

  img {
    width: 6.6rem;
    height: 2.04rem;
  }

  h3 {
    font-size: 1.6rem;
    color: #203251;
    font-weight: 600;
  }
}


.ant-dropdown-menu.ant-dropdown-menu-root.ant-dropdown-menu-vertical.ant-dropdown-menu-light {
  padding: 4px 8px;
}

.ant-dropdown-menu-item.ant-dropdown-menu-item-only-child {
  height: 32px;
  line-height: 32px;
  border-radius: 2px;

  &:hover {
    background: #ECF2FE;
    color: #0052D9;
  }
}

//头部右边部分
.header_right {
  padding-right: 3.2rem;

  .tadium_switching {
    .ant-dropdown-trigger {
      min-width: 9rem;
      cursor: pointer;
    }

    //小箭头
    .drop_down {
      transition: all 0.5s;
      transform: rotate(180deg) translateY(-2px);

      &.active {
        transform: rotate(0deg) translateY(0px);
      }
    }
  }

  .stadium_icon img {
    width: 4rem;
    height: 4rem;
  }

  .drop_down {
    margin-left: 6px;

    img {
      width: 1.4rem;
      height: 1.4rem;
      vertical-align: middle;
    }
  }


  .notice_icon {
    &:hover{
      .ant-badge{
        .anticon{
          color: #0052D9;
        }
        
      } 
    }
    img {
      width: 2.4rem;
      height: 2.4rem;
    }
  }

  .avatar {
    img {
      width: 4rem;
      height: 4rem;
    }
  }

}

//登录页面的样式
[data-component=login] {
  .login_inner {
    height: 100vh;
    padding: 0 13% 0 9rem;

    .welcome_box {
      img {
        width: 51.6rem;
      }
    }

    .form_main {
      width: 49rem;
      height: 54rem;
      background: #FFFFFF;
      box-shadow: 5px 10px 30px 0px rgba(26, 57, 204, 0.12);
      border-radius: 1.2rem;
      padding: 5rem 6rem;

      .form_logo {
        img {
          width: 13rem;
          height: 4rem;
        }
      }

      .ant-input-prefix {
        color: #0052D9;
      }

      h3 {
        font-size: 2.6rem;
        color: #000;
        margin: 2.5rem 0;
      }

      .form_title {
        font-size: 1.6rem;
        color: rgba(0, 0, 0, 0.65);
        margin-bottom: 2.4rem;
      }
    }
  }

}
@primary-color: #0052D9;