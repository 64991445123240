//公共样式
.search_box {
  background: #fff;
  padding: 2.4rem 3.2rem;
  margin-bottom: 2.4rem;
  border-radius: 2px;
}

.ant-form label {
  color: rgba(0, 0, 0, 0.65);
}

.ant-tabs-top>.ant-tabs-nav,
.ant-tabs-bottom>.ant-tabs-nav,
.ant-tabs-top>div>.ant-tabs-nav,
.ant-tabs-bottom>div>.ant-tabs-nav {
  margin: 0;
}

.ant-tabs-content-holder {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.ant-tabs-content,
.ant-tabs {
  flex: 1;
}

//会员管理页面
.play_admin_main {
  .ant-form-inline .ant-form-item {
    margin-right: 2.4rem;
  }
}

//会员管理详情页面
.player_detail_main {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  .ant-tabs>.ant-tabs-nav,
  .ant-tabs>div>.ant-tabs-nav {
    background-color: #fff;
    padding-left: 3.2rem;
  }

  .page_main {
    padding-bottom: 0;
  }

  .breadcrumb {
    padding-bottom: 0.5rem;
  }

  //基础信息
  .player_basics_main {
    .stadium_item {
      background: #FFFFFF;
      border-radius: 2px;
      margin-bottom: 2.4rem;

      .stadium_header {
        padding: 1.6rem 2.4rem 1.6rem 3.2rem;
        border-bottom: 1px solid #E9E9E9;

        h4 {
          font-size: 1.6rem;
          color: rgba(0, 0, 0, 0.85);
        }
      }

      .stadium_body {
        padding: 2.4rem 2.4rem 1.6rem 0;

        .ant-form-item {
          margin-bottom: 1.6rem !important;
        }

        .ant-form-item-control-input-content {
          width: 20rem !important;
          flex: none;
        }
      }
    }

    .cards_list {
      padding: 2.4rem;
      width: 100%;
      display: grid;
      // justify-content: space-between;
      grid-template-columns: repeat(auto-fill, 266px);
      grid-column-gap: 2.4rem;
      grid-row-gap: 2.4rem;

      li {
        height: 18rem;
        border-radius: 1.2rem;
        position: relative;

        &.add_card {
          border: 1px dashed #e9e9e9;
          cursor: pointer;

          .add_position {
            color: rgba(0, 0, 0, 0.65);

            span {
              margin-left: 0.6rem;
            }
          }

          &:hover {
            border-color: #0052D9;

            .add_position {
              color: #0052D9;
            }
          }
        }

        .card_bg {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .card_info {
          position: absolute;
          top: 3.2rem;
          z-index: 9;

          p {
            color: rgba(255, 255, 255, 0.65);
            font-size: 1.4rem;
            margin-top: 1.6rem;
            padding-left: 6.6rem;
          }

          .card_title {
            color: #fff;
            font-size: 2rem;
            padding-left: 3.2rem;
            padding-right: 1.5rem;

            img {
              width: 2.4rem;
              height: 2.4rem;
            }

            span {
              margin-left: 1rem;
              font-weight: 500;
              text-overflow: -o-ellipsis-lastline;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              line-clamp: 1;
              -webkit-box-orient: vertical;
              word-break: break-all;
            }
          }
        }

        .card_setting {
          position: absolute;
          width: 100%;
          bottom: 0;
          height: 4rem;
          background: rgba(255, 255, 255, 0.6);
          box-shadow: 0px -12px 24px 0px rgba(0, 0, 0, 0.08);
          z-index: 9;
          .card_edit,.card_delete{
            color: rgba(0, 0, 0, 0.65);
            cursor: pointer;
            &:hover{
              color: #0052D9;
            }
          }
          img {
            width: 1.6rem;
            height: 1.6rem;
            cursor: pointer;
          }

          div {
            flex: 1;
            text-align: center;
          }
        }
      }
    }
  }

  //统计
  .player_detail_statistic_main {
    height: 100%;
    padding-bottom: 6rem;
  }

  .footer_total {
    height: 6rem;
    padding: 0 5.6rem;

    p {
      font-size: 1.6rem;
      color: rgba(0, 0, 0, 0.65);
      margin-bottom: 0;
    }

    span {
      color: #000;
      font-weight: 500;
    }
  }
}

//卡片类型颜色选择
.card_bg_select {
  padding-left: 0.5rem;

  li {
    width: 3.2rem;
    height: 3.2rem;
    border-radius: 50%;
    cursor: pointer;

    &.active {
      border: 1px solid #0052D9;
    }

    &.green {
      background: linear-gradient(90deg, #6CA8AE 0%, #9DD1D7 100%);
    }

    &.yellow {
      background: linear-gradient(90deg, #DEC396 0%, #EEDAAD 100%);
    }

    &.red {
      background: linear-gradient(90deg, #EF6974 0%, #F99EA6 100%);
    }
  }
}

//权限管理
.authority_management_main {
  height: 100%;
  display: flex;
  flex-direction: column;

  .auth_select {
    margin-top: 3.2rem;

    .auth_select_title {
      font-weight: 600;
      margin-right: 15px;
    }

    .tree_box {
      padding: 14px 20px;
      border: 1px solid #E7E7E7;
      margin-left: 80px;
      height: 650px;
    }

    .ant-space {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
    }

    .look_auth {
      width: 40rem;
    }
  }
}

//修改密码
.change_password_main {
  height: 100%;

  .page_main {
    width: 100%;
    flex: 1;

    .page_inner {
      height: 100%;
    }
  }

  .change_password_inner {
    margin-top: 1rem;
    width: 51rem;
  }

  .change_password_success {
    text-align: center;
    margin-top: 10rem;

    img {
      width: 7.2rem;
      height: 7.2rem;
      margin-bottom: 2.4rem;
    }

    p {
      color: rgba(0, 0, 0, 0.43);
      font-size: 1.4rem;
      margin-bottom: 2.4rem;
    }
  }
}
@primary-color: #0052D9;