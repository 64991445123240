.data_index_main{
  position: relative;
  height: calc(100vh - 6rem);
  overflow: hidden;
  padding-bottom: 6rem;
  .table_box{
    height: inherit;
    
  }
  .footer{
    height: 6rem;
    p{
      margin-bottom: 0;
      font-size: 1.6rem;
      color: rgba(0, 0, 0, 0.65);
      flex:1;
      text-align: right;
      span{
        color: rgba(0, 0, 0, 0.85);
        font-weight: 500;
      }
    }
  }
}

.statistics_chart_main{
  .page_main .page_inner{
    padding-bottom: 0;
  }
  .charts_box{
    flex-wrap: wrap;
    .charts_item{
      width: 49%;
      border: 1px solid #D9D9D9;
      margin-bottom: 3.2rem;
      padding:1.4rem 2.4rem 2.4rem;
      h4{
        font-size: 1.6rem;
        color: rgba(0, 0, 0, 0.85);
        font-weight: 500;
        margin-bottom: 1rem;
      }
      .no_charts{
        height: 40rem;
      }
    }
  }
}
@primary-color: #0052D9;