[data-sider=index_menu] {

  .ant-menu-item-active:hover,
  .ant-menu-light .ant-menu-item:hover,
  .ant-menu-light .ant-menu-submenu-title:hover {
    width: 20.8rem;
    border-radius: 3px;
    background-color: #F3F3F3;
    color: rgba(0, 0, 0, 0.6);

    a {
      color: rgba(0, 0, 0, 0.6);
    }
  }

  .ant-menu-inline .ant-menu-item,
  .ant-menu-inline .ant-menu-submenu-title,
  .ant-menu-submenu,
  .ant-menu-submenu-inline {
    width: 20.8rem;
  }

  .ant-menu-inline.ant-menu-root .ant-menu-item>.ant-menu-title-content,
  .ant-menu-inline.ant-menu-root .ant-menu-submenu-title>.ant-menu-title-content {
    font-size: 1.4rem;
    color: rgba(0, 0, 0, 0.6);

    a {
      color: rgba(0, 0, 0, 0.6);
      font-size: 1.4rem;
    }
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    width: 20.8rem;
    border-radius: 3px;
    background-color: #0052D9;
    color: #fff;

    a {
      color: #fff !important;
      font-size: 1.4rem;
    }

    &:hover {
      background-color: #0052D9;
    }
  }

  .ant-menu-submenu-selected {
    color: rgba(0, 0, 0, 0.6);
  }

  .ant-menu-submenu-expand-icon,
  .ant-menu-submenu-arrow,
  .ant-menu-submenu-inline:hover>.ant-menu-submenu-title>.ant-menu-submenu-arrow,
  .ant-menu-submenu-open.ant-menu-submenu-inline:hover>.ant-menu-submenu-title>.ant-menu-submenu-arrow {
    color: rgba(0, 0, 0, 0.7);
  }

  .ant-menu-submenu-arrow::before,
  .ant-menu-submenu-arrow::after {
    height: 1px;
  }

  .ant-menu-root.ant-menu-vertical,
  .ant-menu-root.ant-menu-vertical-left,
  .ant-menu-root.ant-menu-vertical-right,
  .ant-menu-root.ant-menu-inline {
    padding-left: 8px;
    padding-right: 8px;
  }



  .ant-menu-inline,
  .ant-menu-vertical,
  .ant-menu-vertical-left {
    border-right: none;
  }

}

//消息通知
.order_detail_main {
  .order_detail_header {
    margin-bottom: 1.6rem;

    span {
      color: rgba(0, 0, 0, 0.45);
    }
  }

  h5 {
    margin-bottom: 1.6rem;
  }

  h6 {
    color: rgba(0, 0, 0, 0.85);
    font-size: 1.4rem;
  }

  .tips_box {
    margin: 2.4rem 0;

    .tips_list {
      flex-wrap: wrap;

      .ant-tag {
        margin-bottom: 8px;
      }
    }
  }

  .order_remarks_box {
    .order_remarks {
      width: 100%;
      border: 1px solid #D9D9D9;
      min-height: 8rem;
      border-radius: 2px;
      padding: 6px 15px;
    }
  }
}
@primary-color: #0052D9;