.stadium_info_main {

  //首页公共样式
  .page_main {
    padding-bottom: 0;
  }

  .stadium_item {
    background: #FFFFFF;
    border-radius: 2px;
    margin-bottom: 2.4rem;

    .stadium_header {
      padding: 1.6rem 2.4rem 1.6rem 3.2rem;
      border-bottom: 1px solid #E9E9E9;

      h4 {
        font-size: 1.6rem;
        color: rgba(0, 0, 0, 0.85);
      }
    }

    .stadium_body {
      padding: 2.4rem 2.4rem 1.2rem 0;

      .ant-form-item {
        margin-bottom: 1.6rem !important;
      }

      .ant-form-item-control-input-content {
        width: 20rem !important;
        flex: none;
      }
    }
  }

  //打位设置的组件样式
  .position_settings_list {
    padding-left: 2.4rem;
    width: 100%;
    display: grid;
    justify-content: space-between;
    grid-template-columns: repeat(auto-fill, 250px);

    .settings_item {
      border-radius: 2px;
      border: 1px solid #E9E9E9;
      margin-bottom: 2.4rem;
      min-height: 27rem;
      border-radius: 2px;

      .add_position {
        color: rgba(0, 0, 0, 0.65);
        

        span {
          margin-left: 0.6rem;
        }
      }

      &.setting_add {
        border-style: dashed;
        cursor: pointer;
        &:hover {
          border-color: #0052D9;

          .add_position {
            color: #0052D9;
          }
        }
      }


      .settings_item_header {
        background: #FAFAFA;
        height: 4rem;
        border-bottom: 1px solid #E9E9E9;
        position: relative;
        padding-left: 3rem;
        padding-right: 3.5rem;
        h4 {
          font-size: 1.4rem;
          color: rgba(0, 0, 0, 0.85);
          font-weight: 500;
          text-align: center;
          line-height: 4rem;          
          overflow: hidden;
          text-overflow: ellipsis;
          text-overflow: -o-ellipsis-lastline;
          display: -webkit-box;
          display: -moz-box;
          -webkit-line-clamp: 1;
          -moz-line-clamp: 1;
          line-clamp: 1;         
          -webkit-box-orient: vertical;
          word-break: break-all;
        }

        .more {
          position: absolute;
          right: 8px;
          top: 50%;
          transform: translateY(-50%);
        }
      }

      .settings_item_body {
        padding: 1.6rem 2.4rem;

        li {
          margin-bottom: 0.6rem;
          color: rgba(0, 0, 0, 0.85);

          span {
            color: rgba(0, 0, 0, 0.65);
          }

          .binding {
            color: #48C79C;
            margin-left: 1.4rem;
          }

          .unbound {
            color: #E34D59;
            margin-left: 1.4rem;
          }
        }
      }
    }

  }
}

.basic_info_main {
  background-color: #fff;
}
@primary-color: #0052D9;