//球馆展示
.stadium_display_main {
  .page_main {
    .stadium_page_inner {
      height: 100%;
      padding: 2.4rem 0;
      background-color: #fff;
    }
  }

  .ant-layout {
    height: 100%;
  }

  .ant-layout-sider {
    padding-top: 0;
    border-right-color: #E9E9E9;
  }

  .ant-menu-root.ant-menu-vertical,
  .ant-menu-root.ant-menu-vertical-left,
  .ant-menu-root.ant-menu-vertical-right,
  .ant-menu-root.ant-menu-inline {
    padding-left: 8px;
  }

  .ant-menu-item-selected {
    width: 22.8rem;
    border-right: 3px solid #0052D9;
    background-color: #ECF2FE;
    border-radius: 2px;
    // margin-top: 0;
  }

  .ant-menu-vertical .ant-menu-item,
  .ant-menu-vertical-left .ant-menu-item,
  .ant-menu-vertical-right .ant-menu-item,
  .ant-menu-inline .ant-menu-item,
  .ant-menu-vertical .ant-menu-submenu-title,
  .ant-menu-vertical-left .ant-menu-submenu-title,
  .ant-menu-vertical-right .ant-menu-submenu-title,
  .ant-menu-inline .ant-menu-submenu-title {
    margin-top: 0;
  }

  .ant-layout-content {
    background-color: #fff;
    padding: 0 4rem;

  }

  .ant-menu-inline,
  .ant-menu-vertical,
  .ant-menu-vertical-left {
    border-right: none;
  }

  .price_list_main {
    .remarks {
      margin-top: 3rem;

      .ant-form-item {
        flex: 1;
        margin-bottom: 0;
        margin-right: 1rem;
      }

      .remarks_editable {
        span {
          cursor: pointer;
          margin-left: 0.6rem;
        }
      }
    }
  }
}

//商品信息
.goods_info_main {
  .goods_list {
    display: grid;
    justify-content: space-between;
    grid-template-columns: repeat(auto-fill, 290px);
    grid-row-gap: 2.4rem;
    grid-column-gap: 2.4rem;
    min-height: 100%;

    .goods_item {
      padding: 2.4rem 0 0;
      border: 1px solid #E9E9E9;
      border-radius: 2px;

      .goods_inner {
        padding: 0 2.4rem;
      }

      .goods_item_img {
        width: 100%;
        margin-bottom: 2.2rem;
        border-radius: 2px;

        img {
          width: 16rem;
          height: 16rem;
          border-radius: 2px;
        }
      }

      .goods_item_title {
        margin-bottom: 1rem;

        h5 {
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          line-clamp: 1;
          -webkit-box-orient: vertical;
          word-break: break-all;
        }
      }

      .goods_count {
        color: rgba(0, 0, 0, 0.45);
        font-size: 1.2rem;
        margin-bottom: 1rem;
      }

      .goods_setting {
        width: 100%;
        height: 4rem;
        background-color: #FAFAFA;
        .goods_edit,.goods_delete{
          cursor: pointer;
          color: rgba(0, 0, 0, 0.65);
          &:hover{
            color: #0052D9;
          }
        }
        // img {
        //   width: 1.6rem;
        //   height: 1.6rem;
        //   cursor: pointer;
        // }

        div {
          flex: 1;
          text-align: center;
        }
      }
    }
  }
}

//收益统计
.income_statics_main {
  display: grid;
  grid-template-rows: 9.2rem 1fr;
  height: 100%;

  h4.ant-typography,
  .ant-typography h4 {
    margin-bottom: 0;
    margin-top: 1rem;
  }
}

.sheet_box {
  width: 42.5rem;
  height: 50.7rem;
  padding: 2.4rem;
  background: url('../../assets/imgs/miniproject/sheet.png') center no-repeat;
  background-size: contain;

  .sheet_box_title {
    padding: 0 2rem;
    color: rgba(0, 0, 0, 0.45);
  }

  .sheet_box_content {
    margin-top: 6rem;

    li {
      margin-bottom: 1.6rem;

      p {
        flex: 1;

        &:first-child {
          font-size: 1.4rem;
          text-align: right;
          margin-right: 3.2rem;
        }
      }
    }
  }
}

//二维码
.records_main {
  height: 100%;

  .page_main,
  .page_inner {
    height: 100%;
  }

  .records_inner {
    width: 40rem;
    margin-top: 5rem;

    .submit_box {
      width: 22.8rem;

      .ant-btn {
        width: 48%;
      }
    }
  }

  .ant-btn-dangerous:active,
  .ant-btn-dangerous:hover,
  .ant-btn-dangerous:focus {
    color: #48C79C;
    border-color: #48C79C;
  }

  .records_img {
    background-color: #FBFBFB;
    width: 22.8rem;
    height: 22.8rem;
    border-radius: 8px;
    border: 1px solid #D9D9D9;
    margin-bottom: 2.7rem;

    .records_img_inner {
      width: 100%;
      height: 100%;
      background-color: #fff;
      border-radius: 8px;
      color: rgba(0, 0, 0, 0.25);

      img {
        border-radius: 8px;
        width: 100%;
        height: 100%;
      }
    }

  }
}
@primary-color: #0052D9;