[data-component=tabs] {

  .ant-btn:hover,
  .ant-btn:focus {
    color: #fff;
    background: #0052D9;
  }

  .unsettled_box {
    .anticon {
      vertical-align: -0.25rem !important;
    }
  }
}

//数据统计气泡
.popover_content {
  padding: 1rem 1rem 0;

  .active_account {
    margin-bottom: 24px;

    .active_account_hd {
      margin-bottom: 10px;

      img {
        width: 24px;
        height: 24px;
        margin-right: 6px;
      }
    }
  }

  p {
    font-size: 1.4rem;
    color: rgba(0, 0, 0, 0.65);

    span {
      color: #000;
      font-weight: 500;
    }
  }
}

.welcome {
  font-size: 20px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
}

.tips_content span {
  font-size: 1.4rem;
  color: rgba(0, 0, 0, 0.45);

  &:nth-child(2n) {
    margin: 0 1rem;
  }
}

.index_container {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.manager_title {
  font-size: 1.6rem;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;

  span {
    &:nth-child(2n) {
      font-weight: 500;
    }
  }
}

.manager_status {
  padding: 0.4rem;
  background: #fff;
  border-radius: 0.4rem;
  span {
    width: 4.5rem;
    height: 2.4rem;
    line-height: 2.4rem;
    text-align: center;
    border-radius: 0.4rem;
    cursor: pointer;

    &.active {
      background-color: #0052D9;
      color: #fff;
    }
  }
}

.manager_general {
  margin-bottom: 2.4rem;
}

.manager_general,
.manager_luxury {
  min-height: calc((100vh - 15.5rem - 7.2rem)/2);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.normal_manager_main {
  flex: 1;
  height: 100%;
  display: grid;
}

.manager_header {
  margin-bottom: 2.4rem;
}



// 普通打位
.general_list {
  width: 100%;
  padding-left: 0;
  margin-bottom: 0;
  width: 100%;
  display: grid;
  justify-content: space-between;
  grid-template-columns: repeat(auto-fill, 250px);
  grid-column-gap: 2.4rem;
  grid-row-gap: 2.4rem;

  li {
    border-radius: 0.8rem;
    position: relative;
    background-color: #fff;
    background-size: 100% 100%;
    cursor: pointer;
    border: 1px solid transparent;

    &:hover {
      box-shadow: 0 0 0 2px rgb(0 82 217 / 20%);
      border: 1px solid #0052d9;
    }

    .general_list_inner {
      border-radius: 0.8rem;
      min-height: 211px;
      padding: 1.5rem 2.4rem 1.3rem;
      display: flex;
      flex-direction: column;
      // justify-content:center;
      position: relative;

      .more {
        position: absolute;
        right: 17px;
        top: 10px;
        z-index: 999;
      }

      .inner_title {
        width: 100%;
        height: 2rem;
        position: relative;

        h3 {
          font-size: 1.8rem;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 9;
          font-weight: 500;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          line-clamp: 1;
          -webkit-box-orient: vertical;
          padding-right: 22px;
          word-break: break-all;
        }

        .line {
          position: absolute;
          bottom: -9px;
          left: 0;
          width: 86px;
          height: 10px;
          background: linear-gradient(50deg, #F3F3F3 0%, #FFFFFF 100%);
          border-radius: 5px;
        }
      }
      .place_icon {
        width: 30px;
        height: 30px;
        margin-right: 5px;
      }
      .place_status_container{
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      .place_status {
        margin-top: 1rem;
        .place_playing{
          h4 {
            font-size: 1.4rem;
            font-weight: 500;
          }
          .playing_item{
            margin-bottom: 5px;
          }
        }
        .lock_screen_box{
          height: 64px;
        }
        .lock_screen_wrapper{
          width: 100%;
          height: 4.4rem;
          padding-left: 1.5rem;
          padding-right: 1rem;
          background-color: #ECF2FE;
          font-size: 1.4rem;
          color: #0052D9;
          border-radius: 0.8rem;
        }
        
      
      }
      

      .make_appoint {
        width: 100%;

        p {
          margin-bottom: 0;
          font-size: 12px;
        }
      }

      .make_appoint_empty {
        
        height: 3.4rem;
        display: flex;
        .creat_order{
          margin-bottom: 15px;
          width: 100%;
          text-align: center;
          color: rgba(0, 0, 0, 0.45);
          font-size: 14px;
          align-self: flex-end;
        }
      }

      .manager_lock {
        .place_status_img {
          width: 64px;
          height: 64px;
          background-color: #ECF2FE;
          border-radius: 50%;
        }

        img {
          width: 24px;
          height: 24px;
        }

        h5 {
          font-size: 1rem;
          color: #0052D9;
          margin-top: 8px;
        }
      }
    }
  }
}

//可结账单
.unsettled_list_main {
  width: 100%;
  padding: 0 1.6rem;

  .oreder_unsettled_item {
    width: 100%;
    background-color: #fff;
    padding: 0 1rem 2rem;
    border-radius: 1.6rem;
    margin-bottom: 2rem;
    border: 1px solid transparent;

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      border-color: #0052D9;
      box-shadow: 0 0 0 2px rgb(0 82 217 / 20%);
    }

    .order_info {
      font-size: 1.6rem;
      color: rgba(0, 0, 0, 0.45);
      padding: 1.6rem;
    }


  }

  .index_unsettled_item {
    border-radius: 1.6rem;
    margin-bottom: 2rem;
    border: 1px solid transparent;

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      border-color: #0052D9;
      box-shadow: 0 0 0 2px rgb(0 82 217 / 20%);

      .unsettled_item_inner {
        background-color: #fff;
      }
    }
  }

  .unsettled_item_inner {
    background-color: #F5F5F6;
    padding: 1.6rem 3.2rem;
    border-radius: 1.6rem;

    .ant-btn-dangerous.ant-btn-primary {
      background: none !important;
      background-image: linear-gradient(315deg, #F5694F 20%, #E73827 100%) !important;
      border-color: #E73827;

    }

    .ant-btn-dangerous.ant-btn-primary:active {
      background-image: linear-gradient(315deg, #F5694F 20%, #E73827 100%) !important;
      border-color: #E73827;
    }

    .name {
      font-weight: 500;
      color: rgba(0, 0, 0, 0.85);
      font-size: 18px;
      margin-bottom: 0.5rem;
    }

    p {
      color: #787878;
      font-size: 14px;

      .price {
        color: #DF010A;
      }
    }
  }
}

//订单空白
.empty_main {
  // display: grid;
  // grid-template-rows: 4rem 1fr;
  height: 100%;

}

.empty_inner {
  height: 100%;
  justify-content: center;

  img {
    width: 8rem;
    height: 8rem;
  }

  p {
    color: rgba(0, 0, 0, 0.65);
    margin-top: 0.5rem;
    text-align: center;
  }
}
@primary-color: #0052D9;