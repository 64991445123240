.breadcrumb {
  width: 100%;
  background: #fff;
  padding: 1.6rem 2.4rem 1.6rem 3.2rem;
}

.table_item {
  margin-bottom: 2.4rem;

  .table_title {
    margin-bottom: 1.6rem;
    font-size: 1.6rem;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;

    span {
      color: rgba(0, 0, 0, 0.25);
    }

    &.title-icon {
      position: relative;
      margin-left: 10px;

      &::before {
        content: '';
        position: absolute;
        left: -10px;
        top: 50%;
        transform: translateY(-50%);
        width: 4px;
        height: 14px;
        background-color: #0052D9;
        border-radius: 2px;
      }
    }
  }
}

.margin_bt24 {
  margin-bottom: 2.4rem;
}

// .refresh{  
//   &:hover{
//     color: #0052D9 !important;
//   }
// }

h5 {
  font-size: 1.6rem;
  font-weight: 500;
}

.table_hd {
  margin-bottom: 2rem;
  .setting {
    cursor: pointer;
    color: rgba(0, 0, 0, 0.85);
    font-size: 1.4rem;
  
    &:hover {
      color: #0052D9;
    }
  }
}


.ant-form-inline .ant-form-item {
  margin-right: 2.4rem !important;
}

//布满整个页面
.grid_display {
  display: grid;
  grid-template-rows: 5.4rem 1fr;
  height: 100%;
}

//空白盒子
.empty_box,
.ant-spin-nested-loading,
.ant-spin-container {
  width: 100%;
  height: 100% !important;
}

h5.ant-typography,
.ant-typography h5 {
  margin-bottom: 1.6rem;
}

.mb-5 {
  margin-bottom: 5px;
}
.mb-10 {
  margin-bottom: 10px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-30 {
  margin-bottom: 30px;
}
.mt-10 {
  margin-top: 10px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-30 {
  margin-top: 30px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-50 {
  margin-top: 50px;
}

.mr-30 {
  margin-right: 30px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-70 {
  margin-right: 70px;
}

.mr-15 {
  margin-right: 15px;
}

.ml-15 {
  margin-left: 15px;
}

.ml-10 {
  margin-left: 10px;
}

.mr-10 {
  margin-right: 10px;
}



//推框的底部按钮
.ant-drawer-footer {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #fff;
}

//分页的位置
.pagination_position {
  display: flex;
  justify-content: flex-end;
}

//身份管理和商品类别卡片类型
.identity_main,
.goods_type {
  .title_bg {
    background-color: #fff;
    padding-top: 1.6rem;
    padding-left: 3.2rem;
    border-bottom: 1px solid #E9E9E9;
  }

  .cards_list {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, 20.2rem);
    grid-column-gap: 2.4rem;
    grid-row-gap: 2.4rem;
    justify-content: space-around;

    li {
      height: 14.2rem;
      border-radius: 1.2rem;
      background: url('../../assets/imgs/identity_bg.png') no-repeat center;
      background-size: 100% 100%;
      padding: 2.5rem;
      border: 1px solid #E9E9E9;

      &.add_card {
        border-style: dashed;
        background: none;
        cursor: pointer;

        .add_position {
          color: rgba(0, 0, 0, 0.65);

          span {
            margin-left: 0.6rem;
          }
        }

        &:hover {
          border-color: #0052D9;

          .add_position {
            color: #0052D9;
          }
        }
      }

      .card_info {
        .card_id {
          color: rgba(0, 0, 0, 0.25);
          font-size: 1.4rem;
          margin-top: 1rem;
        }

        .card_title {
          p {
            color: rgba(0, 0, 0, 0.65);
            font-size: 1.6rem;
          }

          .card_edit {
            cursor: pointer;

            &:hover {
              color: #0052D9;
            }
          }
        }
      }
    }
  }
}

//商品类型
.goods_type {
  .cards_list {
    li {
      background: url('../../assets/imgs/type_bg.png') no-repeat center;
      background-size: 100% 100%;
    }
  }
}

//可结账单
.unsettled_modal {
  .ant-drawer-body {
    padding: 0 !important;
  }
}

//table表格编辑
.edit_icon {
  cursor: pointer;

  &:hover {
    color: #0052D9;
  }
}